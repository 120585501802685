<template>
  <div>
    <h2>{{ $t('views.users.changetracking.title') }}</h2>

    <entity-changetracking-table :entityBy="eParent"/>
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityChangetrackingTable from '@/components/entity/ChangetrackingTable'

export default {
  name: 'Users.ChangeTracking',
  mixins: [MixinEntityBase],
  components: {
    EntityChangetrackingTable
  }
}
</script>

<style lang="scss"></style>
